/* Default */

button {
  border: none;
  background: none;
  outline: none;
}

/* Colors */

$primaryColor: #264876;
$darkerPrimaryColor: #13253f;
$lightBlue: #42A5F5;
$secondaryColor: #e0e3ec;
$darkerSecondaryColor: #cfd3e2;
$danger: #FF5959;
$darkerDanger: #f84545;
$greyColor: #949494;
$lightGrey: #eeeeee;
$fadeGrey: rgba(38, 72, 118, 0.4);

/* Mixin */

@mixin scrollbar($class, $radius) {

  #{$class}::-webkit-scrollbar {
    width: 10px;
  } /* width */

  #{$class}::-webkit-scrollbar-track {
    background: $secondaryColor;
    border-radius: #{$radius};
  } /* Track */

  #{$class}::-webkit-scrollbar-thumb {
    background: $primaryColor;
    border-radius: 10px;
  } /* Handle */

  #{$class}::-webkit-scrollbar-thumb:hover {
    background: $darkerPrimaryColor;
  } /* Handle on hover */

}

#react-doc-viewer #header-bar {
  display: none;
}
