@import '../../Styles.scss';

.wrapper-spinner {
  width: 100%;
  height: 100%;
  position: relative;

  .loader {
    color: $primaryColor;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}