
html, 
body, 
#root {
  height: 100%;
}

ul {
  list-style: none;
  padding: 0;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none !important;
  cursor: pointer !important;
  color: inherit !important;
}

input:focus {
  outline: none;
}

input:focus {
  outline: none;
}

input {
  border: none;
}

input:focus {
  outline: none;
}

button {
  cursor: pointer;
}

button:focus {
  outline: none !important;
}

p, h1 {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
